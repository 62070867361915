import React from "react";
import theme from "theme";
import { Theme, Text, Hr, Box, Section } from "@quarkly/widgets";
import { Helmet } from "react-helmet";
import { GlobalQuarklyPageStyles } from "global-page-styles";
import { RawHtml, Override } from "@quarkly/components";
import * as Components from "components";
export default (() => {
	return <Theme theme={theme}>
		<GlobalQuarklyPageStyles pageUrl={"services"} />
		<Helmet>
			<title>
				Our Services | Kodlama Dünyası Hizmetlerimiz
			</title>
			<meta name={"description"} content={"Kodlama Sanatıyla Geleceği Şekillendirin – Hizmet Yelpazemize Dalın"} />
			<meta property={"og:title"} content={"Our Services | Kodlama Dünyası Hizmetlerimiz"} />
			<meta property={"og:description"} content={"Kodlama Sanatıyla Geleceği Şekillendirin – Hizmet Yelpazemize Dalın"} />
			<meta property={"og:image"} content={"https://jolixup.com/img/1.jpg"} />
			<link rel={"shortcut icon"} href={"https://jolixup.com/img/pngtree-vector-programming-icon-png-image_780480.jpg"} type={"image/x-icon"} />
			<link rel={"apple-touch-icon"} href={"https://jolixup.com/img/pngtree-vector-programming-icon-png-image_780480.jpg"} />
			<link rel={"apple-touch-icon"} sizes={"76x76"} href={"https://jolixup.com/img/pngtree-vector-programming-icon-png-image_780480.jpg"} />
			<link rel={"apple-touch-icon"} sizes={"152x152"} href={"https://jolixup.com/img/pngtree-vector-programming-icon-png-image_780480.jpg"} />
			<link rel={"apple-touch-startup-image"} href={"https://jolixup.com/img/pngtree-vector-programming-icon-png-image_780480.jpg"} />
			<meta name={"msapplication-TileImage"} content={"https://jolixup.com/img/pngtree-vector-programming-icon-png-image_780480.jpg"} />
		</Helmet>
		<Components.Header />
		<Section background="url(https://jolixup.com/img/1.jpg) center/cover" padding="140px 0" sm-padding="40px 0" quarkly-title="About-16">
			<Override slot="SectionContent" align-items="center" justify-content="center" sm-min-width="280px" />
			<Box
				padding="50px 80px 50px 50px"
				color="--dark"
				display="flex"
				flex-direction="column"
				align-items="center"
				max-width="980px"
				background="#ffffff"
				sm-padding="50px 24px 50px 24px"
			>
				<Text margin="0 0 16px 0" as="h2" font="--headline2">
				Hizmet Öne Çıkanlarımız
				</Text>
				<Hr
					min-height="10px"
					margin="0px 0px 0px 0px"
					border-color="--color-dark"
					border-width="3px 0 0 0"
					max-width="50px"
					width="100%"
				/>
				<Text font="--base" text-align="center">
				Kodlama Dünyası olarak, sizleri dijital dünyada bir adım öne taşıyacak çeşitli hizmetler sunuyoruz. Temel kodlama derslerinden ileri düzey programlama tekniklerine kadar, her seviyeden öğrenciye hitap eden kurslarımızla, teknolojiyi şekillendirme gücünü elinize alın.
				</Text>
			</Box>
		</Section>
		<Section padding="100px 0 100px 0" background="--color-light" quarkly-title="FAQ-8">
			<Text margin="0px 0px 35px 0px" font="normal 600 42px/1.2 --fontFamily-sans" color="--darkL1">
			Kurs Kategorilerimiz
			</Text>
			<Box
				min-width="100px"
				min-height="100px"
				display="grid"
				grid-template-columns="repeat(3, 1fr)"
				grid-gap="50px 50px"
				lg-grid-template-columns="repeat(2, 1fr)"
				md-grid-template-columns="1fr"
				sm-grid-gap="35px 0"
			>
				<Box min-width="100px" min-height="100px">
					<Text margin="0px 0px 0px 0px" font="normal 300 16px/1.5 --fontFamily-sansHelvetica" color="#555a5f">
					Başlangıç Seviyesi Kursları: Kodlamaya yeni başlayanlar için tasarlanmış, temel kavramlar ve basit projelerle dolu kurslar.
					</Text>
				</Box>
				<Box min-width="100px" min-height="100px">
					<Text margin="0px 0px 0px 0px" font="normal 300 16px/1.5 --fontFamily-sansHelvetica" color="#555a5f">
					Orta Seviye Kursları: Daha önce kodlama deneyimi olan ancak becerilerini ileriye taşımak isteyen öğrenciler için ideal.
					</Text>
				</Box>
				<Box min-width="100px" min-height="100px">
					<Text margin="0px 0px 0px 0px" font="normal 300 16px/1.5 --fontFamily-sansHelvetica" color="#555a5f">
					İleri Seviye Kursları: Karmaşık projeler ve gelişmiş programlama tekniklerini öğrenmek isteyenler için derinlemesine eğitimler.
					</Text>
				</Box>
				<Box min-width="100px" min-height="100px">
					<Text margin="0px 0px 0px 0px" font="normal 300 16px/1.5 --fontFamily-sansHelvetica" color="#555a5f">
					Gerçek Hayat Simülasyonları: Öğrencilerin gerçek dünya problemlerine çözüm geliştirebilmeleri için tasarlanmış projeler.
					</Text>
				</Box>
				<Box min-width="100px" min-height="100px">
					<Text margin="0px 0px 0px 0px" font="normal 300 16px/1.5 --fontFamily-sansHelvetica" color="#555a5f">
					Portföy Geliştirme: Öğrencilerin, iş görüşmelerinde gösterebilecekleri kendi kodlama projelerini oluşturmalarına yardımcı olmak.
					</Text>
				</Box>
				<Box min-width="100px" min-height="100px">
					<Text margin="0px 0px 0px 0px" font="normal 300 16px/1.5 --fontFamily-sansHelvetica" color="#555a5f">
					Canlı Destek: Öğrencilerin her türlü sorusuna yanıt vermek için uzman eğitmenlerimizle canlı destek hizmeti.
					</Text>
				</Box>
			</Box>
		</Section>
		<Components.Footer />
		<RawHtml>
			<style place={"endOfHead"} rawKey={"65323428c5fd0f0020b15081"}>
				{":root {\n  box-sizing: border-box;\n}\n\n* {\n  box-sizing: inherit;\n}"}
			</style>
		</RawHtml>
	</Theme>;
});